import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  CreateMmEngagementRequestBody,
  CreateMmEngagementResponseBody,
  GetMmEngagementsQuerySchema,
  GetMmEngagementsResponseBody,
  MarketMakerPathParam,
  MarketMakerResult,
} from '../types'

const c = initContract()

export const liquidityMarketMakerContract = c.router({
  getEngagedProjects: {
    method: 'GET',
    path: '/liquidity-market-maker/engaged-projects',
    responses: {
      200: z.array(
        z.object({
          projectId: z.string().uuid(),
          projectName: z.string(),
          imageUrl: z.string().nullable(),
        }),
      ),
    },
    summary: 'Get market maker engaged projects',
  },
  getEngagements: {
    method: 'GET',
    path: '/liquidity-market-maker/engagements',
    query: GetMmEngagementsQuerySchema,
    responses: {
      200: GetMmEngagementsResponseBody,
    },
    summary: 'Get engagements of a market maker',
  },
  createEngagement: {
    method: 'POST',
    path: '/liquidity-market-maker/engagements',
    body: CreateMmEngagementRequestBody,
    responses: {
      200: CreateMmEngagementResponseBody,
    },
    summary: 'Create an engagement',
  },
  getMarketMaker: {
    method: 'GET',
    path: '/liquidity-market-maker/:marketMakerId',
    pathParams: MarketMakerPathParam,
    query: z.object({
      projectId: z.string().uuid().optional(),
    }),
    responses: {
      200: MarketMakerResult,
    },
    summary: 'Get market maker by Id.',
  },
})

export type LiquidityMarketMakerContractType = typeof liquidityMarketMakerContract
